'use strict';

/**
 * Hides the loader
 */

(function (window, document, undefined) {

    var loader = document.getElementById('js-loader');

    window.onload = function () {
        loader.classList.add('site-loader_hide');

        setTimeout(function () {
            loader.style.display = 'none';
        }, 3000);
    };
})(window, document);