'use strict';

/**
 * Modal handlers
 */

(function (window, document, undefined) {

    var modalOpener = document.getElementsByClassName('js-openModal'),
        modal = document.getElementById('js-modal'),
        modalCloser = document.getElementById('js-closeModal'),
        firstInput = modal.querySelector('input');

    /**
     * Shows Modal
     */
    for (var i = 0; i < modalOpener.length; i++) {
        modalOpener[i].addEventListener('click', function () {
            modal.classList.add('modal_active');
            firstInput.focus();
        });
    }

    /**
     * Closes Modal
     */
    modalCloser.addEventListener('click', function () {
        modal.classList.remove('modal_active');
        firstInput.blur();
    });
})(window, document);