'use strict';

/**
 * Init the custom video player
 */

(function (window, document, undefined) {

    var playerContainer = document.getElementById('js-playerContainer'),
        video = document.getElementById('js-player');

    if (!Modernizr.webp) {
        video.poster = video.getAttribute('data-poster-alt');
    }

    var player = new Plyr('#js-player', {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'airplay', 'fullscreen']
    });

    player.on('playing', function (event) {
        playerContainer.classList.remove('media-container_hide_controls');
    });
})(window, document);