'use strict';

/**
 * Creates decor for social icons
 */

(function (window, document, undefined) {

    var socialLinks = document.getElementsByClassName('social-block__item');

    for (var i = 0; i < socialLinks.length; i++) {
        var canvas = document.createElement('canvas'),
            cw = 145,
            ch = 145,
            ctx = canvas.getContext("2d"),
            strokeColor = socialLinks[i].getAttribute('data-color') || '#fff';

        canvas.width = cw;
        canvas.height = ch;
        canvas.classList.add('social-block__canvas');
        socialLinks[i].appendChild(canvas);

        var hexagon = new Hexagon(ctx, cw, ch, strokeColor);
        hexagon.draw();
    }
})(window, document);