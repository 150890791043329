'use strict';

/**
 * Creates Toast
 */

function Toast() {

    var toast = void 0;

    function removeToast() {
        toast.parentNode.removeChild(toast);
    }

    this.create = function (message, status) {

        if (message === '') return;

        toast = document.createElement('div');
        toast.classList.add('toast', 'toast_active');
        toast.innerHTML = message;

        document.body.appendChild(toast);

        if (status) {
            toast.classList.add('toast_status_success');
        } else {
            toast.classList.add('toast_status_error');
        }

        setTimeout(function () {
            removeToast();
        }, 4000);
    };
}