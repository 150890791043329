'use strict';

/**
 * Create the float button
 */

(function (window, document, undefined) {

    var disappearButton = document.getElementById('js-disappearButton'),
        floatBlock = document.getElementById('js-floatBlock'),
        canvas = floatBlock.querySelector('canvas'),
        cw = 145,
        ch = 145,
        ctx = canvas.getContext("2d"),
        strokeColor = floatBlock.getAttribute('data-color') || '#fff';

    canvas.width = cw;
    canvas.height = ch;

    var hexagon = new Hexagon(ctx, cw, ch, strokeColor);
    hexagon.draw();

    var disappearButtonTop = disappearButton.getBoundingClientRect().top + window.pageYOffset;

    window.addEventListener('scroll', function () {

        if (window.pageYOffset + 100 > disappearButtonTop) {
            disappearButton.classList.add('btn_disappear');
            floatBlock.classList.add('float-block_active');
        } else {
            disappearButton.classList.remove('btn_disappear');
            floatBlock.classList.remove('float-block_active');
        }
    });
})(window, document);