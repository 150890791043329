'use strict';

/**
 * Rotates the labyrinth on scroll
 */

(function (window, document, undefined) {

    var labyrinth = document.getElementById('js-labyrinth');

    window.addEventListener('scroll', function () {
        var theta = window.pageYOffset / 1000 % Math.PI;

        labyrinth.style.transform = 'rotate(' + theta + 'rad)';
    });
})(window, document);