'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/**
 * Creates the rain fall
 */

(function (window, document, undefined) {

    var canvas = document.getElementById('js-rainContainer'),
        ctx = canvas.getContext('2d');

    var canvasWidth = 960,
        canvasHeight = 1000,
        windowWidth = document.body.clientWidth;

    if (windowWidth < 960) {
        canvasWidth = windowWidth;
        canvas.style.left = '0';
    } else {
        canvas.style.left = windowWidth / 2 - canvasWidth / 2 + 'px';
    }

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    var rainFall = new RainFall(ctx);

    rainFall.draw();

    function RainFall(ctx) {
        this.ctx = ctx;
        this.numArr = [10, 10, 10, 30, 50, 70, 90, 180];
        this.gapArr = [20, 20, 20, 20, 50, 100, 200, 400];
        this.dropsArr = createDropsArray(this.numArr, this.gapArr);

        this.draw = function () {
            updateCanvas(this.ctx, this.dropsArr);
        };

        /**
         * Makes drop moving
         */
        function updateCanvas(canvasContext, dropsArr) {
            draw(canvasContext, dropsArr);

            requestAnimationFrame(function () {
                updateCanvas(canvasContext, dropsArr);
            });
        }

        /**
         * Creates array of drops
         *
         * @param {array} numArr
         * @param {array} gapArr
         */
        function createDropsArray(numArr, gapArr) {
            var array = [];

            for (var i = 0; i < canvasWidth / 30; i++) {

                var j = 0,
                    subArray = [],
                    rowHeight = 0,
                    speed = getRandomInt(0.5, 1);

                while (rowHeight < canvasHeight) {

                    var elY = void 0,
                        elWidth = 10,
                        gapX = 30,
                        elX = i * gapX,
                        gapY = getRandomEl(gapArr),
                        elHeight = getRandomEl(numArr);

                    rowHeight += elHeight + gapY;

                    if (rowHeight > canvasHeight) {
                        break;
                    }

                    if (j > 0) {
                        elY = subArray[subArray.length - 1].y + subArray[subArray.length - 1].height + subArray[subArray.length - 1].gapY;
                    } else {
                        elY = getRandomEl(gapArr);
                    }

                    subArray.push({ x: elX, y: elY, width: elWidth, height: elHeight, gapY: gapY, speed: speed });

                    j++;
                }

                array.push(subArray);
            }

            return array;
        }

        /**
         * Get a random integer
         *
         * It returns integer between min (inclusive) and max (inclusive).
         *
         * @param {number} min
         * @param {number} max
         */
        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        /**
         * Get random element from array.
         *
         * @param {array} array
         */
        function getRandomEl(array) {
            return array[Math.floor(Math.random() * array.length)];
        }

        /**
         * Draws drops
         *
         * @param {CanvasRenderingContext2D} ctx - Canvas 2d context.
         * @param {array} array - The array of drops.
         */
        function draw(ctx, array) {

            var len = array.length;

            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            for (var i = 0; i < len; i++) {

                for (var j = 0; j < array[i].length; j++) {

                    ctx.fillStyle = '#383A3C';

                    drawRect(ctx, array[i][j].x, array[i][j].y, array[i][j].width, array[i][j].height, 5);

                    array[i][j].y += array[i][j].speed;

                    if (array[i][j].y > canvasHeight) {

                        if (j === array[i].length - 1) {

                            if (array[i][0].y < 0) {
                                array[i][j].y = array[i][0].y - array[i][j].height - array[i][0].gapY;
                            } else {
                                array[i][j].y = -array[i][j].height - array[i][j].gapY;
                            }
                        } else {

                            if (array[i][j + 1].y < 0) {
                                array[i][j].y = array[i][j + 1].y - array[i][j].height - array[i][j + 1].gapY;
                            } else {
                                array[i][j].y = -array[i][j].height - array[i][j].gapY;
                            }
                        }
                    }
                }
            }
        }

        /**
         * Draws a rounded rectangle using the current state of the canvas
         *
         * If you omit the last param, it will draw a rectangle
         * outline with a 5 pixel border radius
         *
         * @param {CanvasRenderingContext2D} ctx - Canvas 2d context.
         * @param {number} x - The top left x coordinate.
         * @param {number} y - The top left y coordinate.
         * @param {number} width - The width of the rectangle.
         * @param {number} height - The height of the rectangle.
         * @param {number} radius - The corner radius. Defaults to 5.
         */
        function drawRect(ctx, x, y, width, height, radius) {

            if ((typeof radius === 'undefined' ? 'undefined' : _typeof(radius)) === undefined) {
                radius = 5;
            }

            ctx.beginPath();

            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x + radius, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);

            ctx.closePath();
            ctx.fill();
        }
    }
})(window, document);