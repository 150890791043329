'use strict';

/**
 * Hexagon Constructor
 *
 * @param {CanvasRenderingContext2D} ctx - Canvas 2d context.
 * @param {number} cw - Canvas width.
 * @param {number} ch - Canvas height.
 * @param {string} strokeColor - Color of stroke. Default '#fff'.
 */
function Hexagon(ctx, cw, ch, strokeColor) {
    this.ctx = ctx;
    this.cw = cw;
    this.ch = ch;
    this.strokeColor = strokeColor || '#fff';
    this.rectangles = [{
        sideCount: 6,
        size: 50,
        centerX: this.cw / 2,
        centerY: this.ch / 2,
        strokeWidth: 1,
        rotate: 100,
        speed: 0.5,
        clockwise: true,
        strokeColor: this.strokeColor,
        rotationDegrees: getRandomInt(0, 360)
    }, {
        sideCount: 6,
        size: 45,
        centerX: this.cw / 2,
        centerY: this.ch / 2,
        strokeWidth: 1,
        rotate: 0,
        speed: 1,
        clockwise: false,
        strokeColor: this.strokeColor,
        rotationDegrees: 0
    }];

    /**
     * Draws canvas elements
     */
    this.draw = function () {
        updateCanvas(this.ctx, this.cw, this.ch, this.rectangles);
    };

    /**
     * Updates canvas
     *
     * @param {CanvasRenderingContext2D} ctx - Canvas 2d context.
     * @param {number} cw - Canvas width.
     * @param {number} ch - Canvas height.
     * @param {array} rectangles
     */
    function updateCanvas(ctx, cw, ch, rectangles) {

        ctx.clearRect(0, 0, cw, ch);

        for (var i = 0; i < rectangles.length; i++) {
            var el = rectangles[i];

            drawRect(ctx, el.sideCount, el.size, el.centerX, el.centerY, el.strokeWidth, el.strokeColor, el.rotationDegrees);

            if (el.clockwise) {
                el.rotationDegrees += el.speed;
            } else {
                el.rotationDegrees -= el.speed;
            }
        }

        requestAnimationFrame(function () {
            updateCanvas(ctx, cw, ch, rectangles);
        });
    }

    /**
     * Get a random integer
     *
     * It returns integer between min (inclusive) and max (inclusive).
     *
     * @param {number} min
     * @param {number} max
     */
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * Draws rect
     *
     * @param {CanvasRenderingContext2D} ctx - Canvas 2d context.
     * @param {number} sideCount - The number of rectangle sides.
     * @param {number} size - The size of rectangle sides.
     * @param {number} centerX
     * @param {number} centerY
     * @param {number} strokeWidth - The width of strokes.
     * @param {string} strokeColor - The color of strokes.
     * @param {number} rotationDegrees
     */
    function drawRect(ctx, sideCount, size, centerX, centerY, strokeWidth, strokeColor, rotationDegrees) {
        var radians = rotationDegrees * Math.PI / 180;
        ctx.save();
        ctx.translate(centerX, centerY);
        ctx.rotate(radians);
        ctx.beginPath();
        ctx.moveTo(size * Math.cos(0), size * Math.sin(0));

        for (var i = 0; i <= sideCount; i++) {

            ctx.lineTo(size * Math.cos(i * 2 * Math.PI / sideCount), size * Math.sin(i * 2 * Math.PI / sideCount));
        }

        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = strokeWidth;
        ctx.stroke();
        ctx.restore();
    }
}