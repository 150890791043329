'use strict';

/**
 * Insert current year
 */

(function (window, document, undefined) {
    var currentYearHolder = document.getElementById('js-currentYear');

    currentYearHolder.innerHTML = String(new Date().getFullYear());
})(window, document);