'use strict';

/**
 * Form handlers
 */

(function (window, document, undefined) {

    var forms = document.getElementsByClassName('form');

    var _loop = function _loop(i) {
        var submitButton = forms[i].querySelector('[type="submit"]');

        submitButton.addEventListener('click', function (event) {
            if (!validateForm(forms[i])) {
                event.preventDefault();
            }
        });

        forms[i].addEventListener('submit', function (event) {
            submitFormAjax(this);
            event.preventDefault();
        });
    };

    for (var i = 0; i < forms.length; i++) {
        _loop(i);
    }

    /**
     * Add input mask
     */
    Inputmask({ "mask": "+9 (999) 999-99-99" }).mask('[type="tel"]');

    /**
     * Shows error
     *
     * @param {object} container
     * @param {string} errorMessage
     */
    function showError(container, errorMessage) {
        container.classList.add('error');

        var msgElem = document.createElement('span');
        msgElem.className = 'form__error-message';
        msgElem.innerHTML = errorMessage;
        container.appendChild(msgElem);
    }

    /**
     * Deletes error
     *
     * @param {object} container
     */
    function deleteError(container) {
        container.classList.remove('error');

        if (container.lastChild.className === 'form__error-message') {
            container.removeChild(container.lastChild);
        }
    }

    /**
     * Validates form
     *
     * @param {object} form
     */
    function validateForm(form) {

        var els = form.elements,
            requiredElements = form.querySelectorAll("[required]");

        var numErrors = 0;

        for (var i = 0; i < els.length; i++) {
            deleteError(els[i].parentNode);

            //tel input
            if (els[i].value && els[i].type === 'tel') {
                if (!validateTel(els[i].value)) {
                    numErrors++;
                    showError(els[i].parentNode, 'Некорректный номер телефона.');
                }
            }

            //required fields
            if (els[i].hasAttribute('required')) {
                if (!els[i].value) {
                    numErrors++;
                    showError(els[i].parentNode, 'Обязательное поле.');
                }
            }
        }

        return !numErrors;
    }

    /**
     * Validates tel
     *
     * @param {string} tel - The phone number.
     */
    function validateTel(tel) {
        var reg = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;

        return reg.test(tel);
    }

    /**
     * Ajax form
     *
     * @param {object} form
     */
    function submitFormAjax(form) {

        var formData = new FormData(form),
            action = form.getAttribute('action'),
            xmlHttp = new XMLHttpRequest();

        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                var result = JSON.parse(xmlHttp.responseText),
                    modal = document.getElementById('js-modal'),
                    toast = new Toast();

                modal.classList.remove('modal_active');

                setTimeout(function () {
                    toast.create(result.message, result.success);
                }, 1000);
            }
        };

        xmlHttp.open('POST', action, true);
        xmlHttp.send(formData);
    }
})(window, document);